h1,
h2,
h3,
h4,
h5,
h6, 
button {
  color: #1f1f1f;
}

button {
  font-family: "Inter", sans-serif;
}

.App {
  height: 100%;
}

.box {
  &_header {
    text-align: center;
    padding: 15px;
  }
}

.main-color {
  color: #1BADFF;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  border: 1px solid #e5e5e6;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
  gap: 1px;
  &_link {
    padding: 10px;
    color: #1f1f1f;
    font-size: unset;
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    text-wrap: nowrap;
    flex: 1;
    &--selected {
      color: #1BADFF;
      cursor: default;
    }
  }
}

.items-center {
  align-items: center;
}

.main-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  padding: 0.625rem;
}

.receipt-main-container {
  gap: 1.5rem;
  display: flex !important;
  flex-direction: column;
  padding-left: 0!important;
  padding-right: 0!important;
  & .card-container {
    padding: 1.25rem;
    border-radius: 5px;
    border: 1px solid #e5e5e6;
    & .receipt-container {
      & .nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & .logo {
          height: 50px;
          object-fit: contain;
        }
        & h1 {
          text-align: right;
        }
      }
      & .header {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        margin-top: 2rem;
        & .field {
          display: flex;
          justify-content: space-between;
          text-align: right;
          gap: 1rem;
          font-size: 14px;
          color: #1f1f1f;
        }
      }
      & .body {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        margin-top: 2rem;
        & .field {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem 0;
          text-align: right;
          gap: 1rem;
          color: #1f1f1f;
          & .title {
            font-weight: 700;
            text-align: left;
          }
          & .total-price {
            font-size: 1.5rem;
            font-weight: 700;
          }
        }
        & .field:not(:last-child) {
          border-bottom: 0.5px solid #e5e5e6;
        }
      }
    }
  }
  & .payment-infos {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    font-size: 12px;
    & .field {
      display: flex;
      justify-content: space-between;
      text-align: right;
      gap: 1rem;
      color: #1f1f1f;
    }
    & .text-button {
      appearance: none;
      border: none;
      background-color: transparent;
      text-align: left;
      padding: 0;
      margin-top: 0.5rem;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }
  & .accordion-panel {
    background-color: #F4F6FD;
    border-radius: 5px;
    border: none;
    &::before {
      display: none;
    }
    & .accordion-separator {
      height: 1px;
      background-color: #E5E5E6;
      width: 90%;
      margin: 0 auto 10px auto;
    }
    & .accordion-header {
      display: flex;
      gap: 10px;
      align-items: center;
      & .accordion-header-title {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  & .buttons-container {
    display: flex;
    gap: 1rem;
    & button {
      flex: 1;
      padding: 1.25rem;
      border: none;
      border-radius: 5px;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s;
      &:active {
        transform: scale(0.95);
      }
    }
    & .main-button {
      background-color: #042859;
      color: #fff;
      &:disabled { 
        background-color: #d8d8d8;
        color: #fff;
        pointer-events: none;
        cursor: not-allowed;

      }
    }
    & .secondary-button {
      background-color: #fff;
      border: 1px solid #d8d8d8;
      color: #1f1f1f;
    }
  }
  & .email-container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    & input {
      padding: 1rem;
      border: 1px solid #d8d8d8;
      border-radius: 5px;
      &:focus {
        outline: none;
        border-color: #1badff;
        border-width: 1px;
      }
    }
    & button {
      padding: 1rem;
      border: none;
      border-radius: 5px;
      background-color: #1badff;
      color: #fff;
      cursor: pointer;
      font-weight: 600;
      transition: all 0.3s;
      &:active {
        transform: scale(0.95);
      }
    }
    & .rgdp-info {
      font-size: 12px;
      color: #818181;
      text-align: center;
    }
  }
  & .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  & .alert-message {
    font-weight: 600;
  }
  & .not-found-container {
    background-color: #E7F6FF;
    border-radius: 5px;
    & .not-found-message-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 2rem;
      gap: 2rem;
      & .icon-info {
        fill: #1BADFF;
        font-size: 5rem;
      }
      & .not-found-message-messages {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        & .not-found-title-message {
          font-size: 1.25rem;
          font-weight: 600;
        }
        & .not-found-subtitle-message {
          font-size: 0.875rem;
          color: #818181;
        }
      }
    }
     & .card-container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
     }
  }
}

.ticket-wrapper {
  position: relative;
  & .copy-all-wrapper {
    position: absolute;
    right: 0;
    top: 0;
  }
  & .content-e-ticket {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
}
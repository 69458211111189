@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

html {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1f1f1f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}